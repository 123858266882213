/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/Footer"

import { TopSection } from "../../styles/pages/produtos"

import { FiArrowLeft, FiArrowUpRight } from "react-icons/fi"

import colors from "../../styles/colors"

import Image from "../../components/Produtos/Detalhe/cristal-tubo-limao-sal"

import RicoEmGingerol from "../../images/Produtos/Detalhes/Icones/rico-em-gingerol.svg"
import Vegano from "../../images/Produtos/Detalhes/Icones/vegano.svg"
import Tormogenico from "../../images/Produtos/Detalhes/Icones/tormogenico.svg"
import NaoContemGluten from "../../images/Produtos/Detalhes/Icones/nao-contem-gluten.svg"
import NaoContemSidio from "../../images/Produtos/Detalhes/Icones/nao-contem-sodio.svg"
import NaoContemCorantes from "../../images/Produtos/Detalhes/Icones/nao-contem-corantes.svg"
import ZeroAcucar from "../../images/Produtos/Detalhes/Icones/zero-acucar.svg"

function ProdutoDetalhePage() {
  return (
    <Layout>
      <SEO title="Cristais de gengibre em tubo sabor limão e sal" />
      <Header />
      <TopSection>
        <h1>Delícias naturais à base de gengibre para alegrar seu dia!</h1>
      </TopSection>
      <Container>
        <CardContainer>
          <CardContainerHeader>
            <Link to="/produtos">
              <FiArrowLeft size={25} />
              Voltar para Produtos
            </Link>
          </CardContainerHeader>
          <CardContainerContentHeader>
            <CardContainerContentHeaderLeft>
              <div>
                <Image />
              </div>
            </CardContainerContentHeaderLeft>
            <CardContainerContentHeaderRight>
              <h2>Cristais de gengibre em tubo sabor limão e sal</h2>
              <p>Sabores:</p>
              <OpcoesContainer>
                <Link to="../cristal-tubo-limao-sal">Limão Sal</Link>
                <Link to="../cristal-tubo-menta">Menta</Link>
                <Link to="../cristal-tubo-maracuja">Maracujá</Link>
                <Link to="../cristal-tubo-acerola">Acerola</Link>
                <Link to="../cristal-tubo-anis">Anis</Link>
                <Link to="../cristal-tubo-canela">Canela</Link>
              </OpcoesContainer>

              <p className="descricao">
                Combate o tártaro, gengivite, amigdalite, faringite, atua na
                proteção das cordas vocais e é rico em fibras.
              </p>

              <a
                href="https://munddi.com/ardrak/insta"
                target="_blank"
                rel="noreferrer"
              >
                Saiba onde encontrar <FiArrowUpRight size={22} />
              </a>
            </CardContainerContentHeaderRight>
          </CardContainerContentHeader>
          <CardContainerContentBody>
            <CardContainerContentBodyLeft>
              <h3>Informações nutricionais</h3>
              <table>
                <tr>
                  <th>Porção de 10g (1 tubo)</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <td>Valor energético</td>
                  <td>15 kcal = 63 kJ</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Gorduras totais</td>
                  <td>1,6 g</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Fibra alimentar</td>
                  <td>3,5 g</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>Sódio</td>
                  <td>363 mg</td>
                  <td>15</td>
                </tr>
              </table>
              <p>
                Não contém quantidade significativa de proteína, gordura total,
                saturada, trans e sódio”.
              </p>
              <p>
                * Valores Diários de Referência com base em uma dieta de 2.000
                kcal ou 8.400 kJ. Seus valores diários podem ser maiores ou
                menores dependendo de suas necessidades energéticas.
              </p>
              <h4>Ingredientes</h4>
              <div className="ingredientes">
                <p>
                  Gengibre, sal, suco de limão desidratado, cravo, canela,
                  alfavaca e acidulante (cítrico). Não contém glúten.
                </p>
              </div>
            </CardContainerContentBodyLeft>
            <CardContainerContentBodyRight>
              <h3>Extras</h3>

              <div>
                <img src={RicoEmGingerol} alt="Rico em Gingerol" />
                <p>Rico em Gingerol</p>
              </div>
              <div>
                <img src={Vegano} alt="Vegano" />
                <p>Vegano / sem origem animal</p>
              </div>
              <div>
                <img src={Tormogenico} alt="Tormogênico" />
                <p>Tormogênico</p>
              </div>
              <div className="verde">
                <img src={NaoContemGluten} alt="Não contém glutén" />
                <p>Não contém glutén</p>
              </div>
              <div className="verde">
                <img src={NaoContemSidio} alt="Não contém sódio" />
                <p>Não contém sódio</p>
              </div>
              <div className="verde">
                <img src={NaoContemCorantes} alt="Não contém corantes" />
                <p>Não contém corantes</p>
              </div>
              <div className="verde">
                <img src={ZeroAcucar} alt="Zero açúcar" />
                <p>Zero açúcar</p>
              </div>
            </CardContainerContentBodyRight>
          </CardContainerContentBody>
        </CardContainer>
      </Container>
      <Footer />
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  margin: 0px auto;

  padding-top: 20px;
  background-color: #f9f9f9;

  @media (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 499px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`

const CardContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 20px auto;
  min-height: 900px;

  border: 1px solid #f3f3f3;
  border-radius: 10px;
`

const CardContainerHeader = styled.div`
  height: 84px;
  border-bottom: 2px solid #f3f3f3;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;

  a {
    text-decoration: none;
    color: ${colors.VERDE};
    font-size: 22px;

    svg {
      position: relative;
      top: 7px;
      margin-right: 5px;
    }

    &:hover {
      color: ${colors.LARANJA_ESCURO};
    }
  }

  @media (max-width: 499px) {
    padding-left: 20px;
    height: 64px;
  }

  @media (max-width: 320px) {
    padding-left: 20px;
    height: 64px;
  }
`

const CardContainerContentHeader = styled.div`
  display: flex;
  border-bottom: 2px solid #f3f3f3;

  @media (max-width: 830px) {
    flex-direction: column;
  }
`
const CardContainerContentHeaderLeft = styled.div`
  width: 100%;
  max-width: 677px;
  min-height: 523px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-right: 2px solid #f3f3f3;

  > div {
    width: 180px;
  }

  @media (max-width: 1000px) {
    > div {
      padding-left: 20px;
      width: 140px;
    }
  }

  @media (max-width: 830px) {
    max-width: 100%;
    padding-right: 0px;
    justify-content: center;
    min-height: fit-content;
    padding-top: 40px;
  }

  @media (max-width: 499px) {
    padding-top: 0px;
    > div {
      padding: 20px;
    }
  }
`
const CardContainerContentHeaderRight = styled.div`
  width: 100%;
  max-width: 523px;
  min-height: 523px;

  padding-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 40px;
  padding-right: 20px;

  > p {
    color: #979797;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
    color: #40463f;
  }

  p.descricao {
    font-size: 22px;
    color: ${colors.CINZA_CONTATO_CARD};
    line-height: 25px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: auto;
    margin-bottom: 40px;

    padding: 12px 20px 4px 20px;

    vertical-align: middle;

    background-color: ${colors.VERDE_CLARO};
    border-radius: 20px;

    font-size: 20px;
    line-height: 1px;
    text-decoration: none;
    text-transform: uppercase;
    color: ${colors.VERDE};

    svg {
      position: relative;
      top: -4px;
      margin-left: 5px;
    }

    &:hover {
      color: ${colors.BRANCO_PADRAO};
      background-color: ${colors.LARANJA_ESCURO};
    }
  }

  @media (max-width: 830px) {
    min-height: fit-content;
    > a {
      margin-top: 40px;
    }

    p.descricao {
      padding-right: 20px;
    }
  }

  @media (max-width: 499px) {
    padding: 20px;

    > a {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 320px) {
    padding: 20px;

    > a {
      font-size: 16px;
    }
  }
`

const OpcoesContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: ${colors.VERDE};
    text-decoration: none;
  }

  img {
    margin-right: 10px;
    max-width: 58px;
  }
`

const CardContainerContentBody = styled.div`
  display: flex;

  @media (max-width: 781px) {
    flex-direction: column;
  }
`

const CardContainerContentBodyLeft = styled.div`
  width: 100%;
  max-width: 677px;
  min-height: 523px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: flex-start;
  padding-left: 40px;
  padding-top: 40px;
  padding-right: 40px;

  h3 {
    font-size: 28px;
    color: #40463f;
    font-weight: bold;
    margin-bottom: 25px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  td,
  th {
    border: none;

    text-align: left;
    padding: 8px;
  }

  td {
    border-right: 1px solid white;
    text-align: left;
    padding: 8px 9px 9px 9px;
  }

  td.no-border {
    border: none;
  }

  tr:nth-child(even) {
    background-color: #e8e8e8;
  }

  tr:nth-child(odd) {
    background-color: #f6f6f6;
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;

    color: #707070;
    width: 100%;
    max-width: 474px;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: bold;
    color: #40463f;
  }

  div.ingredientes {
    background-color: #f6f6f6;
    padding: 20px;
    margin-bottom: 40px;
  }

  @media (max-width: 499px) {
    padding: 20px;

    div.ingredientes {
      margin-bottom: 20px;
      padding: 20px 20px 4px 20px;
    }
  }
`

const CardContainerContentBodyRight = styled.div`
  width: 100%;
  max-width: 523px;
  min-height: 523px;

  padding-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0px;

  h3 {
    font-size: 28px;
    color: #40463f;
    font-weight: bold;
    margin-bottom: 25px;
  }

  div {
    width: 100%;
    max-width: 277px;
    display: flex;
    align-items: center;
    height: 50px;

    p {
      margin-top: 7px;
      color: ${colors.LARANJA_ESCURO};
      font-size: 22px;
      line-height: 24px;
      margin-left: 10px;
      font-weight: 500;
    }

    margin-bottom: 20px;
  }

  div.verde {
    height: 50px;
    width: 100%;
    max-width: 277px;
    display: flex;
    align-items: center;

    p {
      margin-top: 7px;
      color: ${colors.VERDE_ESCURO};
      font-size: 22px;
      line-height: 24px;
      margin-left: 10px;
      font-weight: 500;
    }

    margin-bottom: 20px;
  }

  @media (max-width: 781px) {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  @media (max-width: 499px) {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
  }
`

export default ProdutoDetalhePage
